"use client";

import { useState } from "react";

import { redirect } from "next/navigation";

import classNames from "classnames";

import { applyPasswordFromForm } from "./authServerActions";

import styles from "./AuthPage.module.scss";

export function AuthPage({ next }: { next?: string }) {
	const [isSubmitted, setIsSubmitted] = useState(false);

	return (
		<div className={styles.container}>
			<h1 className={styles.title}>Password Required</h1>

			<form
				action={async (form: FormData) => {
					await applyPasswordFromForm(form);

					// Trigger revalidation to refresh client-side cache
					// revalidatePath("/");

					// Redirect to URL path the user was trying to visit
					redirect(next as string);
				}}
				onSubmit={() => setIsSubmitted(true)}
				className={styles.form}
			>
				<fieldset className={styles.fieldset}>
					<label htmlFor="password" className={styles.label}>
						Password
					</label>
					<input id="password" name="password" type="password" required />
				</fieldset>

				<input
					type="submit"
					value="Submit"
					disabled={isSubmitted}
					className={classNames(
						styles.submitButton,
						isSubmitted ? styles.submitButtonDisabled : "",
					)}
				/>

				{isSubmitted && (
					<div>
						<p>
							{
								"Password submitted, if you got it right you'll be in momentarily."
							}
						</p>
						<p>{"If you got it wrong, reload this page and try again."}</p>
					</div>
				)}
			</form>
		</div>
	);
}
